/* Color definitions */
:root {
	--primary: #2e4468;
	--primarydark: #4a618691;
	--primaryhalf: #526c96;
	--primarylight: #2e4468af;
	--primarysuperlight: #2e4468;
	--secondary: #3c763d;
	--secondarymedium: #2b552c;
	--secondarydark: #162c17;
	--secondarylight: #77df79;
	--secondaryhalf: #54a857;
	--tertiary: #2e4468;
	--tertiarylight: #7285a3;
	--quartary: #ca5800;
	--quartarydark: #351802;
	--quartarylight: #c49978;
}

body {
	margin: 0;
	padding: 0;
	font-family: "Open Sans";
}

h1 {
	font-family: "Open Sans Condensed";
}

h2 {
	font-size: 36px;
	color: var(--secondary);
	font-family: "Open Sans Condensed";
	font-weight: 800;
}

h3 {
	font-family: "Open Sans Condensed";
	font-size: 24px;
	color: var(--tertiary);
	font-weight: 800;
}

h4 {
	font-family: "Open Sans Condensed";
	color: var(--quartary);
}

h5 {
	font-family: "Open Sans Condensed";
}

.btn-primary {
	filter: saturate(90%);
	background-color: #005fd3;
	border-color: #005fd3;
}

.badge-success {
	background-color: #0a8927;
}

.nav-link {
	color: #0363c9;
}

.nav-pills .nav-link.active {
	background-color: #0070f4 !important;
}

.progress-bar {
	background-color: #0363c9;
}

label {
	font-weight: 600;
	font-stretch: extra-expanded;
	color: var(--primary);
}

.centered {
	display: block;
	margin: 0 auto;
}

.title {
	font-weight: 600;
	font-stretch: extra-expanded;
	color: var(--primary);
}

.empty-button {
	background: none;
	border: none;
	cursor: pointer;
}

#popover-contained {
	padding: 5px;
}

.modal-100w {
	max-width: none !important;
	width: 100%;
	margin: 0;
	padding: 0;
}

.modal-90w {
	max-width: none !important;
	width: 90%;
}

.modal-75w {
	max-width: none !important;
	width: 75%;
}

.modal-60w {
	max-width: none !important;
	width: 60%;
}

.modal-50w {
	max-width: none !important;
	width: 50%;
}

.modal-40w {
	max-width: none !important;
	width: 40%;
}

.modal-30w {
	max-width: none !important;
	width: 30%;
}

#popover-data .arrow {
	display: none;
}

.modal-close {
	font-weight: 700;
	line-height: 1;
}

a {
	color: #016ee3;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

.badge-primary {
	background-color: #016ee3;
}

.media {
	display: flex;
	align-items: flex-start;
}

.media-body {
	-ms-flex: 1;
	flex: 1;
}

.jumbotron {
	padding: 2rem 1rem;
	margin-bottom: 2rem;
	background-color: #e9ecef;
	border-radius: 0.3rem;
}
